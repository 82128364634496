/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=IBM+Plex+Sans&display=swap');

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: row;
  background-color: #12181b; /* Main content background */
  font-family: 'IBM Plex Mono', monospace; /* Default font for the app */
}

.sidebar {
  flex: 1;
  background-color: #1a252f; /* Sidebar background */
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start */
  box-sizing: border-box;
  position: relative;
}

.sidebar h1 {
  font-size: 32px; /* Larger font size */
  margin: 10px 0; /* Reduced margin */
  position: absolute;
  top: 20px; /* Adjust top position */
  left: 20px; /* Adjust left position */
  font-family: 'IBM Plex Sans', sans-serif; /* Title font */
}

.sidebar .bio {
  margin-top: 70px; /* Adjust margin to fit under the title */
  font-size: 14px; /* Smaller font size for bio */
}

.sidebar nav {
  margin-top: 20px; /* Reduced margin to move nav closer to the bio */
  width: 100%;
}

.sidebar nav ul {
  list-style: none;
  padding: 0;
}

.sidebar nav ul li {
  margin: 5px 0; /* Reduced margin for closer buttons */
  cursor: pointer;
}

.sidebar .social-links {
  margin-top: 20px; /* Add some space above social links */
  display: flex;
  justify-content: center;
  gap: 10px; /* Add space between icons */
  width: 100%;
}

.sidebar .social-links a {
  color: white;
  text-decoration: none;
}

.sidebar .social-links .social-icon {
  width: 24px;
  height: 24px;
}

.content {
  flex: 3; /* Adjust flex ratio for content area */
  padding: 20px;
  box-sizing: border-box;
  color: white;
  position: relative; /* Make content position relative */
  overflow: hidden; /* Hide overflow to prevent scrollbars during animation */
  display: flex;
  justify-content: flex-start; /* Align content to the top */
  align-items: flex-start; /* Align content to the top */
}

.page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; /* Align items in a column */
  justify-content: flex-start; /* Align content to the top */
  align-items: flex-start; /* Align content to the top */
  position: absolute;
}

.page-enter {
  transform: translateX(100%);
  opacity: 0;
}
.page-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
}
.page-exit {
  transform: translateX(0);
  opacity: 1;
}
.page-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
}
